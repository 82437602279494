// @flow

// import { REHYDRATE } from 'redux-persist';

import type {Action, LettersState} from '../../types';

//import {LETTERS} from '../../constants/OBJECT_KEYS';


// TODO: I should replace isCalcLetterPrice, isSendingLetter (and maybe other flags? - review) with a submissionState with possible values such as init, calculating, sending, sent.
const initialState = {
  isCreatingReturnAddress: false,
  isCalcLetterPrice: false,
  isSendingLetter: false,
  returnAddress: null,
  destinationAddress: null,
  totalPrice: 0,
  extraService: ""
}

export default function lettersReducer(state: LettersState = initialState, action: Action) {

  switch(action.type) {
    // case REHYDRATE: {
    //   if (action.key === LETTERS && action.payload) {
    //     const newState = {
    //       ...state,
    //       ...action.payload,
    //       isFileUploading: false,
    //       isCalcLetterPrice: false,
    //       isSendingLetter: false,
    //       isCreatingReturnAddress: false,
    //       totalPrice: 0,
    //       // could probably tweak this, so that the file remained
    //       remoteFileName: null,
    //       remoteFileURL: null
    //     };
    //     return newState;
    //   }
    //   return state;
    // }
    case 'UPLOAD_FILES':
    case 'UPLOAD_FILES_SUCCESS':
    case 'REMOVE_FILE_SUCCESS': {
      return {
        ...state,
        totalPrice: 0
      }
    }
    case 'UPDATE_ADDRESS': {
      const {formName, ...address} = action.payload;

      const newState = {
        ...state,
        [formName]: {
          ...address
        },
        totalPrice: 0
      };
      if (formName === 'returnAddress') {
        return {
          ...newState,
          isCreatingReturnAddress: true
        }
      }
      return newState;
    }
    // NOTE: only US addresses are verified
    case 'VERIFY_ADDRESS_SUCCESS': {

      const {payload} = action;
      if (payload.formName === 'returnAddress') {
        return {
          ...state,
          returnAddress: {
            ...state.returnAddress,
            state: action.payload.state ? action.payload.state : state.returnAddress.state
          },
          isCreatingReturnAddress: false
        };
      }

      return {
        ...state,
        destinationAddress: {
          ...state.destinationAddress,
          state: action.payload.state ? action.payload.state : state.destinationAddress.state
        }
      };

    }
    case 'CALC_LETTER_PRICE': {
      return {
        ...state,
        extraService: action.payload.extraService,
        isCalcLetterPrice: true
      }
    }
    case 'CALC_LETTER_PRICE_SUCCESS': {
      return {
        ...state,
        returnAddress: {
          ...state.returnAddress,
          provider_addr_id: action.payload.providerAddrId
        },
        totalPrice: action.payload.totalPrice,
        isCalcLetterPrice: false
      }
    }
    case 'SUBMIT_LETTER': {
      return {
        ...state,
        isSendingLetter: true
      }
    }
    case 'SUBMIT_LETTER_SUCCESS': {
      return {
        ...initialState
      }
    }
    case 'ERROR': {
      const {errorType} = action.payload;
      if (errorType === 'VERIFY_ADDRESS') {
        return {
          ...state,
          isCreatingReturnAddress: false
        }
      } else if (errorType === 'SUBMIT_LETTER') {
        return {
          ...state,
          isSendingLetter: false
        }
      }
    }
    // no default
  }

  return state;
}
