// @flow

import { Col, Progress, Row, message, Alert } from 'antd';
import React, {Fragment, useEffect, useState} from 'react';
import {
  getDestinationAddress,
  getReturnAddress,
  getTotalPrice
} from '../redux/selectors/lettersSelectors';
import {useDispatch, useSelector} from 'react-redux';

import AntdContainer from '../components/AntdContainer';
import ContentView from '../components/send-letter/ContentView';
import {DynamicModuleLoader} from 'redux-dynamic-modules-react';
import HtmlHead from '../components/HtmlHead';
import SendLetterSteps from '../components/send-letter/SendLetterSteps';
import LetterNotes from '../components/send-letter/LetterNotes';
import Link from '../components/Link';

import {canAutoFetchCountries} from '../redux/selectors/countriesSelectors';
import {fetchCountries} from '../redux/actions/entitiesActions';
import getLettersModule from '../redux/lettersModule';
import {getSortedIds} from '../redux/selectors/filesSelectors';

import {navigate} from 'gatsby';
import queryString from 'query-string';

const numberOfSteps = 4;

const SubmitLetterView = (props: Object) => {

  const dispatch = useDispatch();
  // ... using free heroku dyno, server might be sleeping, so waking it up early
  const canFetchCountries = useSelector(canAutoFetchCountries);
  useEffect(() => {
    if (canFetchCountries) dispatch(fetchCountries())
  }, [canFetchCountries, dispatch]);

  const fileIds = useSelector(getSortedIds);
  const destAddr = useSelector(getDestinationAddress);
  const returnAddr = useSelector(getReturnAddress);
  const totalPrice = useSelector(getTotalPrice);

  const [headings, setHeadings] = useState([
    {title: 'Upload letter files', subtitle: ''},
    {title: "Sender", subtitle: "Please fill in your address"},
    {title: "Recipient", subtitle: "Please fill in recipient's address"},
    {title: "Checkout", subtitle: ''},
  ]);

  useEffect(() => {
    const newHeads = [...headings];
    const checkoutHead = {
      ...newHeads[3],
      subtitle: totalPrice ? `Total: ${totalPrice.toFixed(2)} GBP` : ''
    }
    newHeads[3] = checkoutHead;
    setHeadings(newHeads);
  }, [totalPrice]);

  const {search} = props.location;
  const stepParam = search ? Number(queryString.parse(search).step) : 1;
  const maxAllowedStep = !fileIds.length ? 1 : !returnAddr ? 2 : !destAddr ? 3 : 4;

  useEffect(() => {
    if (stepParam && stepParam > maxAllowedStep) {
      navigate(`/send-letter/`, {replace: true});
      if (!fileIds.length) {
        message.error('Please upload letter file(s)');
      }
    }
  }, [dispatch, maxAllowedStep, stepParam, fileIds]);

  const currentStep = (stepParam && stepParam <= maxAllowedStep) ? stepParam : 1;

  return (
    <Fragment>
      <HtmlHead title={`Send a letter | PostSeal`} meta={{robots: 'noindex'}} />
      <div className='section light'>
        <div className='content-wrapper'>
          <AntdContainer>
            <Row>
              <Col md={{span: 6}} xs={{span: 0}}>
                <SendLetterSteps currentStep={currentStep} noOfFiles={fileIds.length} />
                <LetterNotes />
              </Col>
              <Col xs={{span: 24}} md={{offset: 3, span: 15}}>
                <Row>
                  <Col xs={6} md={0}>
                    <Progress type="dashboard" percent={(currentStep * 25) - 0.001 } format={percent => `${currentStep} of ${numberOfSteps}`} width={70} />
                  </Col>
                  <Col xs={{offset: 2, span: 16}} md={{offset: 0, span: 24}}>
                    <h2 className='ant-typography'>{headings[currentStep - 1].title}</h2>
                    <p style={{fontSize: '16px'}}>{headings[currentStep - 1].subtitle}</p>
                  </Col>
                </Row>

                <div className='top-space-2x bottom-space-2x'>
                  <ContentView key={`submitLetterContent${currentStep}`} currentStep={currentStep} incrementStep={() => currentStep < 4 && navigate(`/send-letter?step=${currentStep + 1}`)} decrementStep={() => currentStep > 0 && navigate(`/send-letter?step=${currentStep - 1}`)} />
                </div>
              </Col>
            </Row>
          </AntdContainer>
        </div>
      </div>
    </Fragment>
  );
};

const DynamicSteps = (props: Object) => {
  return (
    <DynamicModuleLoader modules={[getLettersModule()]}>
      <SubmitLetterView {...props} />
    </DynamicModuleLoader>
  )
}

export default DynamicSteps;
