// @flow

import React, { useEffect, useState } from 'react';
import {Spin, message} from 'antd';

import {DEFAULT_CCY} from '../../constants';
import ReactDOM from 'react-dom';
import {isPrerender} from '../../lib/util';
import loadScript from '../../lib/scriptLoader';
import logger from '../../lib/logger';

function createOrder(data: Object, actions: Object, props: Object) {
  const {price, description, ccy} = props;

  return actions.order.create({
    purchase_units: [{
      description,
      amount: {
        currency_code: ccy,
        value: price
      }
    }]
  });
}

// hacky, but Paypal isn't React friendly
function onInit(data: Object, actions: Object) {

  // Only needed when using checkbox!

  // const checkboxElem = document.getElementById('paypalCheckout_agreement');
  // logger.info(`Paypal onInit, isChecked? ${checkboxElem.checked}`);
  // if (!checkboxElem.checked) {
  //   // Disable the buttons
  //   actions.disable();
  // }
  //
  // // Listen for changes to the checkbox. paypalCheckout_agreement is auto generated by Ant form, assuming it's <form name>_<field name>
  // checkboxElem.addEventListener('change', event => {
  //
  //   logger.info(`Paypal agreement change, isChecked? ${event.target.checked}`);
  //
  //   // Enable or disable the button when it is checked or unchecked
  //   if (event.target.checked) {
  //     actions.enable();
  //   } else {
  //     actions.disable();
  //   }
  // });
}

function onError(error: Object) {
  logger.error(`Paypal error: ${JSON.stringify(error)}`);
  message.error('Paypal payment window failed to open. Please refresh the page and try again.');
}

const PaypalButton = (props: Object) => {
  const [isSdkReady, setSdkReady] = useState(false);

  const {price, description, ccy, onApprove, onClick} = props;

  useEffect(() => {
    if (!isPrerender) {

      logger.info('Loading PayPal sdk');

      loadScript({
        id: 'paypalScript',
        url: `https://www.paypal.com/sdk/js?client-id=${process.env.GATSBY_PAYPAL_CLIENT_ID}&currency=${DEFAULT_CCY}&disable-funding=card`,
        async: true,
        defer: true
      }, () => {
        logger.info('PayPal sdk loaded');
        setSdkReady(true);
      });

    }

  }, []);

  if (!isSdkReady || !window.paypal) {
    return <Spin size='large'/>;
  }

  const PaypalDomBtn = window.paypal.Buttons.driver('react', {
    React,
    ReactDOM
  });

  return (
    <PaypalDomBtn key='paypalDomBtn' className='wide-btn' style={{
      size: 'responsive',
      shape: 'pill',
      label: 'pay',
      height: 48,
    }} onApprove={onApprove} onInit={onInit} onClick={onClick} createOrder={(d, a) => createOrder(d, a, props)} onError={onError} />
  );

}

export default PaypalButton;
