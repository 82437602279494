// @flow

import React, {useEffect, useState} from 'react';
import { Button, Modal, Spin, Divider, message } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import {useDispatch, useSelector} from 'react-redux';
import {downloadFile} from '../../redux/actions/filesActions';
import {getLetterFileName, getLetterFileURL} from '../../redux/selectors/filesSelectors';
import {A4Height, A4Width, USLetterHeight, USLetterWidth} from '../../constants';
import {isPrerender, getBrowserDimensions} from '../../lib/util';

const defaultModalWidth = 660;
const defaultPadding = 32;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function LoadingPage() {
  return (
    <div style={{height:`100%`, width: `100%`}}>
      <Spin />
    </div>
  );
}

function PreviewHeader(props: Object) {

  return (
    <span className='text-center'>
      <h3>Preview letter</h3>
      <h6 className='ant-typography ant-typography-secondary'>A page with addresses will be inserted after submission</h6>
    </span>
  )
}

export default function PreviewFileBtn(props: Object) {

  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const fileUrl = useSelector(getLetterFileURL);
  const letterFileName = useSelector(getLetterFileName);

  useEffect(() => {
    if (modalVisible && letterFileName && !fileUrl) {
      dispatch(downloadFile(letterFileName));
    }
  }, [letterFileName, fileUrl, modalVisible]);

  useEffect(() => {

    if (!isPrerender) {
      function calcScale() {
        const {width} = getBrowserDimensions();
        const maxPageWidth = width - 2 * defaultPadding;
        if (maxPageWidth < USLetterWidth) {
          return maxPageWidth / USLetterWidth;
        }

        return 1
      }

      function handleResize() {
        setScale(calcScale());
      }
      handleResize();

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }

  }, []);

  return (
    <>
      <Modal footer={null} height={A4Height} centered width={defaultModalWidth} visible={modalVisible} title={<PreviewHeader />}
        onCancel={() => setModalVisible(false)} zIndex={2000}
      >

        <Document file={fileUrl}
          onLoadError={error => message.error(`Failed to load the file: ${error.message}`) }
          onLoadSuccess={({numPages}) => setNumPages(numPages)}
          noData={<LoadingPage />}
        >
            {Array.from(new Array(numPages), (el, pageIdx) => (
              <Page key={`page${pageIdx}`} pageNumber={pageIdx + 1} scale={scale}
                loading={<LoadingPage />}
              >
                { pageIdx + 1 !== numPages && <Divider /> }
              </Page>
              ),
            )}

        </Document>

      </Modal>

      <Button size='small' onClick={() => setModalVisible(true)} {...props}>
        Preview
      </Button>
    </>
  )
}
