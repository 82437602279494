// @flow

import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import { message } from 'antd';
import {navigate} from 'gatsby';

import NavBar from './NavBar';
import Uploader from './Uploader';
import PreviewFileBtn from './PreviewFileBtn';

import {getSortedIds} from '../../redux/selectors/filesSelectors';

const UploadStep = (props: Object) => {

  const ids = useSelector(getSortedIds);
  const filesUploaded = ids && ids.length;

  const onNextClick = () => {

    if (!filesUploaded) {
      message.error('Please upload letter file(s)')
    } else {
      navigate('/send-letter?step=2');
    }
  }

  return (
    <Fragment>
      <div className='top-space-2x' />

      <Uploader />

      <div className='top-space-2x bottom-space-2x'>
        <PreviewFileBtn disabled={!filesUploaded} />
      </div>
      {
         //<img id='imgContainer' src={require('./UNADJUSTEDNONRAW_thumb_1ec.jpg')}/>
      }
      <NavBar nextBtnTitle={'Continue'} onNextClick={onNextClick} />
    </Fragment>
  );
}

export default UploadStep;
