// @flow

import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { Alert, Form, Input } from "antd"

import Link from "../Link"
import NavBar from "./NavBar"
import CountriesSelectItem from "../CountriesSelectItem"

import { updateAddress } from "../../redux/actions/lettersActions"

const AddressForm = (props: Object) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [valuesChanged, setValuesChanged] = useState(false)

  const { formName, infoLbl, itemLblPrefix } = props
  const address = props.address ? props.address : {}
  const { name, addressLine1, addressLine2, city, state, postCode } = address

  return (
    <Form
      form={form}
      key={formName}
      name={formName}
      layout="vertical"
      onValuesChange={(changedVals, allVals) => {
        setValuesChanged(true)
      }}
      onFinish={values => {
        if (valuesChanged) {
          dispatch(updateAddress({ formName, ...values }))
        }
        props.incrementStep()
      }}
    >
      <Form.Item className="top-space-2x">
        <Alert
          closable
          message={
            <>
              {props.infoLbl}{" "}
              <Link
                to="/faq?question=address-placement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </Link>
            </>
          }
        />
      </Form.Item>

      <Form.Item
        name="name"
        label={`${itemLblPrefix} name`}
        hasFeedback
        initialValue={name}
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Please input ${itemLblPrefix.toLowerCase()} full name`,
          },
          {
            max: 40,
            message: "The field must be no longer than 40 characters",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="addressLine1"
        label={`${itemLblPrefix} address line 1`}
        hasFeedback
        initialValue={addressLine1}
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Please input ${itemLblPrefix.toLowerCase()} address line 1`,
          },
          {
            max: 50,
            message: "The field must be no longer than 50 characters",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="addressLine2"
        label={`${itemLblPrefix} address line 2`}
        hasFeedback
        initialValue={addressLine2}
        rules={[
          {
            max: 50,
            message: "The field must be no longer than 50 characters",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="city"
        label={`${itemLblPrefix} city`}
        hasFeedback
        initialValue={city}
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Please input ${itemLblPrefix.toLowerCase()} city`,
          },
          {
            max: 50,
            message: "The field must be no longer than 50 characters",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="state"
        label={`${itemLblPrefix} region / state / county`}
        hasFeedback
        initialValue={state}
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Please input ${itemLblPrefix.toLowerCase()} region / state / county`,
          },
          {
            max: 200,
            message: "The field must be no longer than 200 characters",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <CountriesSelectItem
        form={form}
        initVal={address.country}
        name="country"
        label={`${itemLblPrefix} country`}
      />

      <Form.Item
        name="postCode"
        label={`${itemLblPrefix} ZIP / post code`}
        hasFeedback
        initialValue={postCode}
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Please input ${itemLblPrefix.toLowerCase()} post code`,
          },
          {
            max: 40,
            message: "The field must be no longer than 40 characters",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <NavBar onBackClick={props.decrementStep} nextBtnTitle="Continue" />
      </Form.Item>
    </Form>
  )
}

export default AddressForm
