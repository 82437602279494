// @flow

import {call, put, select} from 'redux-saga/effects';
import axios from 'axios';
import {message} from 'antd';
import { getRemoteFileNames, getLetterFileName } from '../selectors/filesSelectors';
import {
  concatFilesSuccess, concatFiles, downloadFile, downloadFileSuccess
} from '../actions/filesActions';
import {fireError} from '../actions/statusActions';

import type {Action, ConcatFilesAction, DownloadFileAction} from '../../types';

export function* onUpdate(action: Action) {
  const letterFileName = yield select(getLetterFileName);
  if (!letterFileName) {
    const fileNames = yield select(getRemoteFileNames);
    if (fileNames && fileNames.length) {
      yield put(concatFiles(fileNames));
    }
  }
}

export function* onConcat(action: ConcatFilesAction) {
  try {
    const {fileNames} = action.payload;

    const URL = `${process.env.GATSBY_API_URL}/v2/files/concat`;
    const {data} = yield call(axios.post, URL, {fileNames});

    yield put(concatFilesSuccess(data));

  } catch(e) {
    const serverErrMsg = e.response && e.response.data ? e.response.data : e.message;
    message.error(`Failed to merge letter files. ${JSON.stringify(serverErrMsg)}`, 15);

    yield put(fireError(e.message, action.type));
  }
}

export function* onDownload(action: DownloadFileAction) {

  try {
    const {fileName} = action.payload;
    const URL = `${process.env.GATSBY_API_URL}/v2/files/${fileName}`;
    const {data} = yield call(axios.get, URL, {
      responseType: 'blob'
    });

    //yield put(downloadFileSuccess(new Uint8Array(data)));
    yield put(downloadFileSuccess(window.URL.createObjectURL(data)));
  } catch(e) {
    const serverErrMsg = e.response && e.response.data ? e.response.data : e.message;
    message.error(`Failed to download letter file. ${JSON.stringify(serverErrMsg)}`, 15);

    yield put(fireError(e.message, action.type));
  }

}
