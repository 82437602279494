// @flow

import type {Action} from '../../types';

export function uploadFiles(): Action {
  return {
    type: 'UPLOAD_FILES',
    payload: {}
  }
}

export function uploadFilesSuccess(files: Array<Object>): Action {
  return {
    type: 'UPLOAD_FILES_SUCCESS',
    payload: {
      files
    }
  }
}

export function removeFileSuccess(id: string): Action {
  return {
    type: 'REMOVE_FILE_SUCCESS',
    payload: {
      id
    }
  }
}

export function concatFiles(fileNames: Array<string>): Action {
  return {
    type: 'CONCAT_FILES',
    payload: {
      fileNames
    }
  }
}

export function concatFilesSuccess(fileName: string): Action {
  return {
    type: 'CONCAT_FILES_SUCCESS',
    payload: {
      fileName
    }
  }
}

export function downloadFile(fileName: string): Action {
  return {
    type: 'DOWNLOAD_FILE',
    payload: {
      fileName
    }
  }
}

export function downloadFileSuccess(fileUrl: string): Action {
  return {
    type: 'DOWNLOAD_FILE_SUCCESS',
    payload: {
      fileUrl
    }
  }
}
