// @flow

import React from 'react';

import {
  Button,
  Row,
  Col
} from 'antd';
import {
  LeftOutlined
} from '@ant-design/icons';

import AgreementText from '../AgreementText';

type Props = {
  +nextBtnTitle?: string,
  +nextBtnDisabled?: boolean,
  +onBackClick?: Function,
  +onNextClick?: Function,
  +isNextBtnLoading?: boolean,
  +nextBtnComp?: Object
}

const NextBtn = (props: Object) => {
  const {
    nextBtnTitle, nextBtnIcon, onNextClick, isNextBtnLoading, nextBtnComp, icon
  } = props;

  if (nextBtnComp) {
    return nextBtnComp;
  }

  return (
    <Button className='wide-btn' type="primary" shape='round' block onClick={onNextClick} htmlType={onNextClick ? null : "submit"} size='large' loading={isNextBtnLoading}>
      {nextBtnTitle} {nextBtnIcon}
    </Button>
  );
}

const NavBar = (props: Props) => {

  const {onBackClick} = props;

  const backBtnVisible = !!onBackClick;

  return (
    <>
    <Row>
      <Col xs={{span: 16, push: 8}} sm={{span: 18, push: 6}} md={{span: 20, push: 4}}>
        <AgreementText btnTitle={props.nextBtnTitle} />
      </Col>
    </Row>
    <Row className='top-space'>
      <Col xs={{span: 8}} sm={{span: 6}} md={{span: 4}}>
      {
        backBtnVisible &&
        <Button size='large' shape='round' onClick={onBackClick} style={{marginRight: '16px'}}><LeftOutlined /></Button>
      }
      </Col>
      <Col xs={{span: 16}} sm={{span: 18}} md={{span: 20}}>
        <NextBtn {...props} />
      </Col>

    </Row>
    </>
  );
};

export default NavBar;
