// @flow

let uppy;

function setInstance(instance: Object) {
  uppy = instance;
}

function getInstance() {
  return uppy;
}

function destroy() {
  if (uppy) {
    uppy.close();
    uppy = null;
  }
}

export default {
  setInstance,
  getInstance,
  destroy
}
