// @flow

import {Col, Divider, Row} from 'antd';

import React from 'react';
import {
  getTotalPrice
} from '../../redux/selectors/lettersSelectors';
import {useSelector} from 'react-redux';

const PriceInfo = (props: Object) => {

  const totalPrice = useSelector(getTotalPrice);
  const formattedPrice = totalPrice && totalPrice.toFixed(2);

  return (
    <div className='top-space'>
      <Divider />
      <Row>
        <Col xs={24}>
          <h2>Total: <span className='pull-right'>{formattedPrice} £</span></h2>
        </Col>
      </Row>

    </div>
  );
}

export default PriceInfo;
