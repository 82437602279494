// @flow

import type {State, FileState, Entities} from '../../types';

import {createSelector} from 'reselect';
import {getFilesState} from './index';

export function areFilesMerging(state: State): boolean {
  return getFilesState(state).filesMerging;
}

export function getFilesById(state: State): Entities<FileState> {
  return getFilesState(state).byId
}

export function getFileState(state: State, id: string): ?FileState {
  return getFilesById(state)[id];
}

export function getRemoteFileName(state: State, id: string): ?string {
  const fileState = getFileState(state, id);
  if (fileState) {
    return fileState.remoteFileName;
  }
  return null;
}

export function getSortedIds(state: State): Array<string> {
  return getFilesState(state).sortedIds;
}

export function getRemoteMergedFileName(state: State): ?string {
  return getFilesState(state).remoteMergedFileName;
}

// Locally saved merged file data
export function getLetterFileURL(state: State): ?string {
  return getFilesState(state).letterFileURL;
}

// clicksend only
export function getProviderFileURL(state: State): ?string {
  return getFilesState(state).providerFileURL;
}

export function getLetterFileName(state: State): ?string {
  const remoteName = getRemoteMergedFileName(state);
  if (remoteName) {
    return remoteName;
  }

  const ids = getSortedIds(state);
  if (ids.length === 1) {
    const idSplit = ids[0].split('.');
    const ext = idSplit[idSplit.length - 1];
    if (ext === 'pdf') {
      return getRemoteFileName(state, ids[0]);
    }
  }

  return null;
}

export function getRemoteFileNames(state: State): Array<string> {
  const ids = getSortedIds(state);

  const fileNames = ids.map(id => {
    return getRemoteFileName(state, id)
  });

  return fileNames;
}

export const getLocalFileNames = createSelector(getSortedIds, ids => {
  return ids.map(id => id.split('/')[1]);
});
