// @flow

import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import Uppy from '@uppy/core';

import DropTarget from '@uppy/drop-target';
import Transloadit from '@uppy/transloadit';
import GoogleDrive from '@uppy/google-drive';
import Dropbox from '@uppy/dropbox';
import OneDrive from '@uppy/onedrive';

import {Dashboard} from '@uppy/react';

import {FILE_SIZE_BYTE_LIMIT} from '../../constants';
import logger from '../../lib/logger';
import {isPrerender} from '../../lib/util';
import uppyHelper from '../../lib/uppyHelper'
import {
  uploadFilesSuccess, removeFileSuccess, uploadFiles
} from '../../redux/actions/filesActions';
import {fireError} from '../../redux/actions/statusActions';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
//import '@uppy/image-editor/dist/style.css';
import '@uppy/drop-target/dist/style.css';

const plugins = isPrerender ? [] : ['GoogleDrive', 'OneDrive', 'Dropbox'];

// uppy is a singleton - not very react friendly
// useUppy causes its state to be wiped out on next step

export default function Uploader(props: Object) {

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {

    const instance = uppyHelper.getInstance();

    if (!instance) {
      const uppy = new Uppy({
        logger,
        autoProceed: true, // auto uploads files
        restrictions: {
          maxFileSize: FILE_SIZE_BYTE_LIMIT,
          maxTotalFileSize: FILE_SIZE_BYTE_LIMIT,
          allowedFileTypes: ['application/pdf', 'image/jpeg', 'image/png']
        },
        onBeforeUpload: files => {
          dispatch(uploadFiles());

          // required to proceed
          return true;
        }
      });

      uppyHelper.setInstance(uppy);
      setLoaded(true);

      if (!isPrerender) {

        const companionParams = {
          companionUrl: Transloadit.COMPANION,
          companionAllowedHosts: Transloadit.COMPANION_PATTERN
        };

        uppy
          .use(DropTarget, {target: document.body})
          //.use(ImageEditor, {id: 'ImageEditor'});
          .use(Transloadit, {
            params: {
              auth: { key: process.env.GATSBY_TRANSLOADIT_KEY },
              template_id: process.env.GATSBY_TRANSLOADIT_TEMPLATE_ID
            },
            limit: 10,
            waitForEncoding: true // needed for transloadit complete evts
          })
          .use(GoogleDrive, companionParams)
          .use(OneDrive, companionParams)
          .use(Dropbox, companionParams)
      }

      uppy.on('transloadit:complete', assembly => {
        if (assembly.ok) {
          const filesPld = assembly.uploads.map(u => {
            return {
              remoteFileName: `${u.id}.${u.ext}`,
              id: `${assembly.assembly_id}/${u.name}`
            }
          });
          dispatch(uploadFilesSuccess(filesPld))
        } else {
          dispatch(fireError(`Upload failed, assembly: ${assembly.assembly_id}, message: ${assembly.message}, error: ${assembly.error}`, 'UPLOAD_FILES'));
        }
      });

      uppy.on('file-removed', (file, reason) => {
        const {transloadit, name} = file;
        dispatch(removeFileSuccess(`${transloadit.assembly}/${name}`));
      });

    }
  }, []);

  const uppy = uppyHelper.getInstance();

  if (uppy) {
    return <Dashboard
        uppy={uppy}
        plugins={plugins}
        proudlyDisplayPoweredByUppy={false}
        hideUploadButton
        doneButtonHandler={null}
        showLinkToFileUploadResult={false}
        showRemoveButtonAfterComplete
        note='Up to 5MB in total. PDF, JPG/JPEG and PNG only.'
        {...props}
      />
  }

  return <></>
}
