// @flow

import React from "react"
import { Alert } from "antd"
import Link from "../Link"

export default function LetterNotes() {
  return (
    <>
      <div className="top-space">
        <Alert
          type="warning"
          message={
            <>
              Letter delivery may take up to 2 weeks depending on the
              destination,{" "}
              <Link
                to="/faq?question=time-to-deliver"
                target="_blank"
                rel="noopener noreferrer"
              >
                check expected delivery
              </Link>
              .
            </>
          }
        />
      </div>
      <div className="top-space">
        <Alert
          type="warning"
          message={`Submitted letters are sent to our partners' print centers instantly, therefore cannot be changed or cancelled.`}
        />
      </div>
    </>
  )
}
