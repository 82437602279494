// @flow

import React, { Fragment, useEffect } from "react"
import {
  Alert,
  Col,
  Collapse,
  Descriptions,
  Radio,
  Row,
  Spin,
  Tabs,
} from "antd"
import { useSelector, useDispatch } from "react-redux"
import Link from "../Link"

import StripeCheckoutView from "./StripeCheckoutView"
import PaypalCheckoutView from "./PaypalCheckoutView"
import PreviewFileBtn from "./PreviewFileBtn"
import LetterNotes from "./LetterNotes"

import {
  getTotalPrice,
  isCalcLetterPrice,
  isSendingLetter,
  isLetterUSOnly,
  getExtraService,
  getReturnAddress,
  getDestinationAddress,
} from "../../redux/selectors/lettersSelectors"
import {
  getLocalFileNames,
  getLetterFileName,
} from "../../redux/selectors/filesSelectors"

import { calcLetterPrice } from "../../redux/actions/lettersActions"

import type { AddressState } from "../../types"

const { TabPane } = Tabs

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
}

const AddressInfo = (props: AddressState) => {
  const {
    name,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postCode,
  } = props
  // return (
  //   <>
  //     {name}, {addressLine1}, {addressLine2 && addressLine2 + ', '} {city}, {state}, {country}, {postCode}
  //   </>
  // )

  return (
    <>
      {name}
      <br />
      {addressLine1}
      <br />
      {addressLine2}
      <br />
      {city}, {state}, {country}
      <br />
      {postCode}
      <br />
    </>
  )
}

const LetterDetails = (props: Object) => {
  const toAddr = useSelector(getDestinationAddress)
  const fromAddr = useSelector(getReturnAddress)
  const fileNames = useSelector(getLocalFileNames)

  // name, addressLine1, addressLine2, city, state, country, postCode

  return (
    <Descriptions
      title=""
      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      size="small"
      layout="vertical"
      bordered
    >
      <Descriptions.Item label="File(s)">
        {fileNames.join(", ")}
        <div className="top-space-2x">
          <PreviewFileBtn />
        </div>
      </Descriptions.Item>
      <Descriptions.Item key="fromDescrItem" label="From">
        <AddressInfo {...fromAddr} />
      </Descriptions.Item>
      <Descriptions.Item key="toDescrItem" label="To">
        <AddressInfo {...toAddr} />
      </Descriptions.Item>
    </Descriptions>
  )
}

function NoTrackingAlert() {
  return (
    <Alert
      type="warning"
      message={
        <>
          Tracking and proof of delivery are not possible.{" "}
          <Link
            to="/faq?question=can-i-track-mail"
            target="_blank"
            rel="noopener noreferrer"
          >
            What does it mean?
          </Link>
        </>
      }
    />
  )
}

const CheckoutView = (props: Object) => {
  const letterFileName = useSelector(getLetterFileName)
  const calculating = useSelector(isCalcLetterPrice)
  const totalPrice = useSelector(getTotalPrice)
  const loadingPrice = calculating || !totalPrice || !letterFileName
  const sending = useSelector(isSendingLetter)

  const extraService = useSelector(getExtraService)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!calculating && !totalPrice && letterFileName) {
      dispatch(calcLetterPrice(extraService))
    }
  }, [dispatch, extraService, totalPrice, calculating, letterFileName])

  return (
    <Spin
      spinning={sending || loadingPrice}
      size="large"
      tip={sending ? "Submitting the letter..." : "Calculating price..."}
    >
      <Row>
        <Col xs={24} lg={0}>
          <Collapse ghost defaultActiveKey="1">
            <Collapse.Panel header="Review letter details" key="1">
              <LetterDetails />
              <LetterNotes />
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>

      <div className="top-space-4x"></div>
      <Tabs defaultActiveKey="stripe" type="card">
        <TabPane tab="Card" key="stripe">
          <div className="top-space"></div>
          <StripeCheckoutView {...props}>
            <NoTrackingAlert />
          </StripeCheckoutView>
        </TabPane>
        <TabPane
          forceRender={false}
          tab={
            <Fragment>
              <img
                alt="paypal"
                src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png"
              />
            </Fragment>
          }
          key="paypal"
        >
          <div className="top-space"></div>
          <PaypalCheckoutView {...props}>
            <NoTrackingAlert />
          </PaypalCheckoutView>
        </TabPane>
      </Tabs>
    </Spin>
  )
}

export default CheckoutView
