// @flow

import React, {Fragment} from 'react';
import {Spin, Statistic, Steps} from 'antd';
import PreviewFileBtn from './PreviewFileBtn';

import {
  getDestinationAddress,
  getReturnAddress,
  getTotalPrice,
  isCalcLetterPrice
} from '../../redux/selectors/lettersSelectors';

import type {AddressState} from '../../types';

import {getLocalFileNames} from '../../redux/selectors/filesSelectors';
import {useSelector} from 'react-redux';

const { Step } = Steps;

function getAddressDescription(address: AddressState) {
  const {
    name, addressLine1, addressLine2, city, state, country, postCode
  } = address;
  return (
    <Fragment>
      {name}<br />
      {addressLine1}<br />
      {addressLine2}<br />
      {city}, {state}, {country}<br />
      {postCode}<br />
    </Fragment>
  );
}

function UploadDesc() {
  const fileNames = useSelector(getLocalFileNames);
  if (fileNames.length) {
    return (
      <>
        <div className='top-space-2x bottom-space'>
          {
            fileNames.join(',\n')
          }
        </div>
        <PreviewFileBtn />
      </>
    );
  }

  return <></>
}

export default function SendLetterSteps(props: Object) {

  const {currentStep} = props;
  const totalPrice = useSelector(getTotalPrice);
  const isCalcPrice = useSelector(isCalcLetterPrice);
  const destinationAddress = useSelector(getDestinationAddress);
  const returnAddress = useSelector(getReturnAddress);

  return (
    <Steps labelPlacement='horizontal' direction="vertical" current={currentStep - 1} size='small'>
      <Step title="Upload" description={<UploadDesc />}  />
      <Step title="Sender" description={returnAddress ? getAddressDescription(returnAddress) : ''}  />
      <Step title="Recipient" description={destinationAddress ? getAddressDescription(destinationAddress) : ''}  />
      <Step title="Checkout" description={!!totalPrice ? <Statistic suffix='£' value={totalPrice} precision={2} /> : (isCalcPrice ? <Spin size='small' /> : '')} />
    </Steps>
  );
}
