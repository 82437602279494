// @flow

import { CardElement, Elements, injectStripe } from "react-stripe-elements"
import { Form, Input, message } from "antd"
import React, { useReducer } from "react"
import {
  getReturnAddress,
  isSendingLetter,
} from "../../redux/selectors/lettersSelectors"
import { useDispatch, useSelector } from "react-redux"
import { LockOutlined } from "@ant-design/icons"

import NavBar from "./NavBar"
import PriceInfo from "./PriceInfo"
import logger from "../../lib/logger"
import stripeFactory from "../../lib/stripeFactory"
import { submitLetter } from "../../redux/actions/lettersActions"

const baseStyle = {
  fontFamily: "inherit",
  fontWeight: "inherit",
  fontSize: "16px",
  textShadow: "inherit",
  color: "#66615b",
  //color: '#000000',
  opacity: "0.65",
  fontSmoothing: "inherit",
  // ':focus': {
  //   color: 'inherit'
  // },
  "::placeholder": {
    color: "#bfbfbf",
  },
}

const elemStyle = {
  base: baseStyle,
  invalid: {
    color: "#f5222d",
  },
}

// function handleSubmit(e: Object, props: Object, cardFieldsValid: boolean, dispatch: Function) {
//   e.preventDefault();
//
//   const {stripe, form} = props;
//
//   if (cardFieldsValid) {
//     stripeFactory.setInstance(stripe);
//     form.validateFieldsAndScroll((err, values) => {
//
//       if (!err && values.agreement && values.email && values.nameOnCard) {
//         dispatch(submitLetter('stripe', values.nameOnCard, null, values.email));
//       }
//     });
//   } else {
//     message.error('Please fill in valid card details');
//   }
// }

function getCardItemError(
  complete: boolean,
  touched: boolean,
  title: string,
  error: ?string
): ?string {
  if (error) {
    return error
  } else if (!complete && touched && title) {
    return `Your card details are incomplete.`
  }
  return ""
}

const initCardInputsState = {
  cardElem: {
    touched: false,
    stripeErr: null,
    complete: false,
  },
}

function cardInputsReducer(state: Object, action: Object) {
  switch (action.type) {
    case "CHANGE": {
      const { error, complete, cardElem } = action.payload

      return {
        ...state,
        [cardElem]: {
          ...state[cardElem],
          stripeErr: error ? error.message : null,
          complete,
        },
      }
    }
    case "BLUR": {
      const { cardElem } = action.payload
      return {
        ...state,
        [cardElem]: {
          ...state[cardElem],
          touched: true,
        },
      }
    }
    default: {
      return state
    }
  }
}

const CardFormItem = (props: Object) => {
  const { htmlFor, title, complete, touched, stripeErr, children } = props

  const cardItemErr = getCardItemError(complete, touched, title, stripeErr)

  return (
    <div
      className={
        (!complete && touched) || stripeErr
          ? "ant-row ant-form-item ant-form-item-with-help ant-form-item-has-feedback ant-form-item-has-error"
          : "ant-row ant-form-item"
      }
    >
      <div className="ant-col ant-form-item-label">
        <label
          htmlFor={htmlFor}
          className="ant-form-item-required"
          title={title}
        >
          {title}
        </label>
      </div>
      <div className="ant-col ant-form-item-control">
        <div className="ant-form-item-control-input">
          <div
            className="ant-form-item-control-input-content"
            style={{ height: "33px" }}
          >
            {children}
          </div>
        </div>
        {!!cardItemErr && (
          <div className="ant-form-item-explain">
            <div role="alert">{cardItemErr}</div>
          </div>
        )}
      </div>
    </div>
  )
}

const CardInputs = (props: Object) => {
  const { cardState, validateCardField } = props

  return (
    <div className="ant-form ant-form-vertical">
      <CardFormItem
        title="Card details"
        htmlFor="cardElem"
        {...cardState.cardElem}
      >
        <CardElement
          id="cardElem"
          className="ant-input"
          style={elemStyle}
          onChange={info =>
            validateCardField({
              type: "CHANGE",
              payload: { cardElem: "cardElem", ...info },
            })
          }
          onBlur={() =>
            validateCardField({
              type: "BLUR",
              payload: { cardElem: "cardElem" },
            })
          }
        />
      </CardFormItem>
    </div>
  )
}

const StripeCheckoutForm = injectStripe((props: Object) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const sending = useSelector(isSendingLetter)
  const returnAddress = useSelector(getReturnAddress)
  const { name } = returnAddress || {}

  const [cardState, validateCardField] = useReducer(
    cardInputsReducer,
    initCardInputsState
  )

  // For some reason touched is false initially on mobile devices
  const isValid = state =>
    /*state.touched && */ !state.stripeErr && state.complete

  const { decrementStep, children } = props

  return (
    <Form
      form={form}
      name="stripeCheckout"
      key="stripeCheckout"
      layout="vertical"
      onFinish={values => {
        if (isValid(cardState.cardElem)) {
          stripeFactory.setInstance(props.stripe)
          dispatch(
            submitLetter("stripe", values.nameOnCard, null, values.email)
          )
        } else {
          message.error("Please fill in valid card details")
          logger.warn(
            `Failed to submit card payment, cardState = ${JSON.stringify(
              cardState
            )}`
          )
        }
      }}
    >
      <Form.Item
        name="email"
        label="Email"
        hasFeedback
        rules={[
          {
            type: "email",
            message: "Please input a valid email address",
          },
          {
            required: true,
            whitespace: true,
            message: "Please input your email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nameOnCard"
        label="Name on card"
        hasFeedback
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please input cardholder's name",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <CardInputs cardState={cardState} validateCardField={validateCardField} />

      <PriceInfo />
      {children}
      <NavBar
        nextBtnTitle="Pay with Card"
        nextBtnIcon={<LockOutlined />}
        onBackClick={decrementStep}
        isNextBtnLoading={sending}
      />
    </Form>
  )
})

const StripeCheckoutView = (props: Object) => {
  return (
    <Elements>
      <StripeCheckoutForm {...props} />
    </Elements>
  )
}

export default StripeCheckoutView
