// @flow

import React from 'react';
import {useSelector} from 'react-redux';
import { globalHistory } from "@reach/router";

import CheckoutView from './CheckoutView';
import UploadStep from './UploadStep';
import AddressForm from './AddressForm';

import {
  getDestinationAddress,
  getReturnAddress
} from '../../redux/selectors/lettersSelectors';

import uppyHelper from '../../lib/uppyHelper';

export default function ContentView(props: Object) {

  const {currentStep, ...stepActions} = props;
  const returnAddr = useSelector(getReturnAddress);
  const destAddr = useSelector(getDestinationAddress);

  React.useEffect(() => {

    return () => {

      const {location} = globalHistory;
      // horrible hack, I should probably remove modules.
      // can't think of anything better that works
      // I only want to destroy uppy when outside of send-letter
      const closeUnmounted = location && location.pathname && location.pathname.indexOf('send-letter') < 0;

      if (closeUnmounted) {
        uppyHelper.destroy();
      }
    }
  }, []);

  if (currentStep === 1) {
    return <UploadStep {...props} />
  }
  else if (currentStep === 2) {
    return (
      <AddressForm key='returnAddressForm' formName='returnAddress' {...stepActions} address={returnAddr} infoLbl='This will be placed as the return address on the letter.' itemLblPrefix="Your" />
    );
  } else if (currentStep === 3) {
    return (
      <AddressForm key='destinationAddressForm' formName='destinationAddress' {...stepActions} address={destAddr} infoLbl="This will be placed as the recipient's address on the letter." itemLblPrefix="Recipient's" />
    );
  }

  return (
    <CheckoutView {...stepActions} />
  );
}
