// @flow

import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { Alert } from "antd"

import PaypalButton from "./PaypalButton"
import NavBar from "./NavBar"
import PriceInfo from "./PriceInfo"

import { getTotalPrice } from "../../redux/selectors/lettersSelectors"
import { submitLetter } from "../../redux/actions/lettersActions"

import { DEFAULT_CCY } from "../../constants"

function onPaypalApprove(data: Object, actions: Object, dispatch: Function) {
  dispatch(submitLetter("paypal", null, data.orderID))
}

const PaypalCheckoutView = (props: Object) => {
  const { decrementStep, children } = props
  const dispatch = useDispatch()
  const totalPrice = useSelector(getTotalPrice)

  return (
    <>
      <Alert
        closable
        message="Letter submission confirmation will be sent to your PayPal email address"
      />
      <PriceInfo />
      {children}
      <NavBar
        onBackClick={decrementStep}
        nextBtnTitle="Pay with PayPal"
        nextBtnComp={
          <PaypalButton
            key="paypalBtn"
            price={totalPrice}
            description="Send mail via PostSeal"
            ccy={DEFAULT_CCY}
            onApprove={(data, actions) =>
              onPaypalApprove(data, actions, dispatch)
            }
          />
        }
      />
    </>
  )
}

export default PaypalCheckoutView
