// @flow

import {AMERICAN_COUNTRIES, DEFAULT_CCY } from '../../constants';
import type {
  SubmitLetterAction,
  UpdateAddressAction,
  SubmitLetterResponse
} from '../../types';

import {calcLetterPriceSuccess, verifyAddressSuccess} from '../actions/lettersActions';
import {call, put, select, take} from 'redux-saga/effects';
import {
  getDestinationAddress,
  getExtraService,
  getReturnAddress,
  getTotalPrice,
} from '../selectors/lettersSelectors';
import {getLetterFileName, getProviderFileURL} from '../selectors/filesSelectors';

import axios from 'axios';
import {fireError} from '../actions/statusActions';
import logger from '../../lib/logger';
import { message } from 'antd';
import {navigate} from 'gatsby';
import stripeFactory from '../../lib/stripeFactory';

export function* onAddressUpdate(action: UpdateAddressAction): Generator<*, void, *> {
  if (AMERICAN_COUNTRIES.indexOf(action.payload.country) >= 0) {
    yield call(_verifyAddress, action);
  }
}

function debugPdfFonts(loadedPdf) {
  console.log(`interesting fields: `);
  let output = '';
  for (const [parentKey, parentVal] of loadedPdf.context.indirectObjects.entries()) {
    if (parentVal.dict && !parentVal.dict.dict) {
      //

      let i = 0;
      let isFontRelated = false;
      for (const[key, val] of parentVal.dict.entries()) {

        if (val.encodedName && val.encodedName.indexOf('Font') >= 0) {
          isFontRelated = true;
        }
      }
      if(isFontRelated) {
        output+='--------------------------------------------------------------------\n';
        for (const[key, val] of parentVal.dict.entries()) {
          output+=`key: ${key}, value: ${val}\n`;
        }
      }
    }
  }
  console.log(output);

  //
}

// Only US from/to letters.
// Note ClickSend return address cannot be created if state isn't 2 char code
function* _verifyAddress(action: UpdateAddressAction): Generator<*, void, *> {
  const {
    name, addressLine1, addressLine2, postCode, country, state, city, formName
  } = action.payload;
  const URL = `${process.env.GATSBY_API_URL}/v2/mail/addresses/verifications`;

  try {
    const res = yield call(axios.post, URL, {
      address_name: name,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      address_city: city,
      address_postal_code: postCode,
      address_country: country,
      address_state: state
    });

    yield put(verifyAddressSuccess(formName, res.data.components.state));
  } catch (e) {
    message.error(`Address verification failed. Please make sure the address is correct.`, 15);
    yield put(fireError(e.message, 'VERIFY_ADDRESS'));
  }
}

export function* calcLetterPriceSaga(action: UpdateAddressAction): Generator<*, void, *> {

  const toAddrPld = yield call(_buildToAddrPld);
  const fromAddrPld = yield call(_buildFromAddrPld);

  const file_name = yield select(getLetterFileName);
  const extraServ = yield select(getExtraService);

  const URL = `${process.env.GATSBY_API_URL}/v2/mail/letters/price`;

  try {
    const res = yield call(axios.post, URL, {
      file_name,
      extra_service: extraServ ? extraServ : null,
      priority_post: 1,
      duplex: 0,
      colour: 1,
      template_used: 0,
      from_address: fromAddrPld,
      to_addresses: [
        toAddrPld
      ]
    });

    const {total_price, file_url, recipients} = res.data;
    const csAddrId = recipients && recipients.length > 0 ? recipients[0].return_address_id : null;

    yield put(calcLetterPriceSuccess(total_price, csAddrId, file_url));
  } catch (e) {
    message.error(`Failed to calculate price of the letter. ${e.message}`, 15);
    yield put(fireError(e.message, 'CALC_LETTER_PRICE'));
  }
}

export function* _doSubmitLetter(action: SubmitLetterAction): Generator<*, SubmitLetterResponse, *> {

  const { payProvider, name } = action.payload;

  if (payProvider === 'paypal') {
    const res = yield call(_createLetterOrder, action);
    return res.data;
  } else {
    const stripe = stripeFactory.getInstance();
    const returnAddress = yield select(getReturnAddress);
    const method = yield call(stripe.createPaymentMethod, 'card', {
      billing_details: {
        name: name || returnAddress.name
      }
    });

    const res = yield call(_createLetterOrder, action, {
      payMethodId: method.paymentMethod.id
    });

    if (res.data.isPayActionRequired) {
      const intent = yield call(stripe.handleCardAction, res.data.payClientSecret);
      if (intent.error && intent.error.message) {
        throw Error(intent.error.message);
      } else {
        const confirmedRes = yield call(_createLetterOrder, action, {
          payIntentId: intent.paymentIntent.id,
          mailId: res.data.mailId
        });
        return confirmedRes.data;
      }
    }

    return res.data;
  }
}

export function* submitLetter(action: SubmitLetterAction): Generator<*, void, *> {

  try {

    const resData = yield _doSubmitLetter(action);

    yield call(_trackConversion);

    navigate('/success/', {
      state: resData
    });
    message.success(`Your letter has been submitted!`);

    //yield put(submitLetterSuccess());

  } catch (e) {
    const serverErrMsg = e.response && e.response.data ? (e.response.data.message ? e.response.data.message : e.response.data) : e.message;

    const fullMsg = `Letter submission failed. ${serverErrMsg}`;
    message.error(fullMsg, 15);
    yield put(fireError(fullMsg, 'SUBMIT_LETTER'));
  }
}

function* _buildFromAddrPld() {
  const address = yield select(getReturnAddress);
  const {
    id, provider_addr_id, name, addressLine1, addressLine2, postCode, country, state, city
  } = address;

  return {
    id,
    provider_addr_id,
    address_name: name,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    address_city: city,
    address_postal_code: postCode,
    address_country: country,
    address_state: state
  }
}

function* _buildToAddrPld() {
  const address = yield select(getDestinationAddress);
  const {
    id, name, addressLine1, addressLine2, postCode, country, state, city
  } = address;

  return {
    id,
    address_name: name,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    address_city: city,
    address_postal_code: postCode,
    address_country: country,
    address_state: state
  }
}

function* _createLetterOrder(action: SubmitLetterAction, stripeArgs: ?Object): Generator<*, XMLHttpRequest, *> {

  const {
    payProvider, orderId, email
  } = action.payload;

  const {payMethodId, payIntentId, mailId} = stripeArgs || {};

  const file_name = yield select(getLetterFileName);
  const file_url = yield select(getProviderFileURL);
  const extraServ = yield select(getExtraService);

  const fromAddrPld = yield call(_buildFromAddrPld);
  const toAddrPld = yield call(_buildToAddrPld);

  const amt = yield select(getTotalPrice);

  const URL = `${process.env.GATSBY_API_URL}/v2/mail/letters`;

  const res = yield call(axios.post, URL, {
    letter: {
      file_name,
      file_url,
      extra_service: extraServ ? extraServ : null,
      priority_post: 1,
      duplex: 0,
      colour: 1,
      template_used: 0,
      from_address: fromAddrPld,
      to_addresses: [
        toAddrPld
      ]
    },
    payInfo: {
      payProvider,
      orderId,
      payMethodId,
      payIntentId,
      amt,
      email,
      mailId, // temporary - should come from webhooks!
      ccy: DEFAULT_CCY
    }
  });

  return res;
}

function* _trackConversion() {
  if (window.gtag && process.env.GATSBY_GTAG_ID) {
    try {
      const amt = yield select(getTotalPrice);
      window.gtag('event', 'conversion', {
        'send_to': `${process.env.GATSBY_GTAG_ID}/${process.env.GATSBY_AD_CONVERSION_ID}`,
        'value': amt,
        'currency': DEFAULT_CCY,
        'transaction_id': '',
        'event_callback': (url) => {
          logger.info('_trackConversion event_callback fired');
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        }
      });
    } catch(e) {
      logger.warn(`Failed to track conversion ${JSON.stringify(e)}`)
    }
  } else {
    logger.warn(`Cannot track conversion, gtag - ${window.gtag}, gtatId - ${process.env.GATSBY_GTAG_ID}`);
  }
}
