// @flow

import type {Action, FilesState} from '../../types';

//import {LOCATION_CHANGE} from 'connected-react-router';
import {omit} from '../../lib/util';

const initialState = {
  byId: {},
  sortedIds: [],
  letterFileURL: null, // local ObjectURL
  providerFileURL: null, // clicksend only
  remoteMergedFileName: null, // merged letter file name in AWS, only populated if there are multiple files
  filesMerging: false,
  filesUploading: false,
  downloading: false
}

export default function filesReducer(state: FilesState = initialState, action: Action): FilesState {

  switch(action.type) {

    case 'UPLOAD_FILES': {
      return {
        ...state,
        filesUploading: true
      }
    }
    case 'UPLOAD_FILES_SUCCESS': {

      const {files} = action.payload;
      const ids = files.map(f => f.id);

      const newById = {...state.byId};
      files.forEach(f => {
        newById[f.id] = {
          id: f.id,
          remoteFileName: f.remoteFileName
        }
      })

      return {
        ...state,
        sortedIds: [...state.sortedIds, ...ids],
        byId: newById,
        filesUploading: false,
        letterFileURL: null,
        remoteMergedFileName: null
      }
    }

    case 'REMOVE_FILE_SUCCESS': {
      const {id} = action.payload;
      const ids = state.sortedIds.filter(i => i !== id);

      return {
        ...state,
        sortedIds: ids,
        byId: omit(state.byId, id),
        letterFileURL: null,
        remoteMergedFileName: null
      }
    }
    case 'CONCAT_FILES': {
      return {
        ...state,
        filesMerging: true
      }
    }
    case 'CONCAT_FILES_SUCCESS': {

      return {
        ...state,
        remoteMergedFileName: action.payload.fileName,
        filesMerging: false
      }
    }
    case 'DOWNLOAD_FILE': {
      return {
        ...state,
        downloading: true
      }
    }
    case 'DOWNLOAD_FILE_SUCCESS': {

      const {fileUrl} = action.payload;

      return {
        ...state,
        downloading: false,
        letterFileURL: fileUrl
      }
    }
    case 'CALC_LETTER_PRICE_SUCCESS': {
      return {
        ...state,
        providerFileURL: action.payload.csFileURL,
      }
    }
    case 'ERROR': {
      const {errorType, metaInfo} = action.payload;
      if (errorType === 'CONCAT_FILES') {
        return {
          ...state,
          filesMerging: false
        }
      } else if (errorType === 'DOWNLOAD_FILE') {
        return {
          ...state,
          downloading: false
        }
      } else if (errorType === 'UPLOAD_FILES') {
        return {
          ...state,
          filesUploading: false
        }
      }
    }

    // no default
  }

  return state;
}
