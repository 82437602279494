// @flow

//import { persistReducer } from 'redux-persist';
//import localForage from 'localforage';
import { all, takeEvery, takeLatest, debounce } from 'redux-saga/effects';
import {
  calcLetterPriceSaga,
  onAddressUpdate,
  submitLetter,
} from './sagas/lettersSagas';

import {onUpdate, onConcat, onDownload} from './sagas/filesSagas';

import lettersReducer from './reducers/lettersReducer';
import filesReducer from './reducers/filesReducer';

//import {LOCATION_CHANGE} from 'connected-react-router';

//import {customMigrate} from './reducers';


//import {LETTERS} from '../constants/OBJECT_KEYS';
//import {getPersistor} from './storeInitialiser';

// NOTE: persistReducer not used anymore
// function* persist(): Generator<*, void, *> {
//   const persistor = yield call(getPersistor)
//   if (persistor) {
//     // NOTE: when initial page is send-letter, persist is called twice, e.g when creating the store and here. Could check if lettersReducer persisted? Any harm calling this twice?
//     yield call(persistor.persist);
//   }
// }

function* rootSaga(args: Array<any>): Generator<*, void, *> {
  yield all([
    takeLatest('UPDATE_ADDRESS', onAddressUpdate),
    takeLatest('SUBMIT_LETTER', submitLetter),
    takeLatest(['UPLOAD_FILES_SUCCESS', 'REMOVE_FILE_SUCCESS'], onUpdate),
    takeLatest('CONCAT_FILES', onConcat),
    takeLatest('DOWNLOAD_FILE', onDownload),
    takeLatest('CALC_LETTER_PRICE', calcLetterPriceSaga),
  ]);
}

export default function getLettersModule() {
  return {
    id: 'lettersModule',
    reducerMap: {
      letters: lettersReducer,
      files: filesReducer,
      // letters: persistReducer({
      //   key: LETTERS,
      //   storage: localForage,
      //   migrate: customMigrate,
      //   stateReconciler: false
      // }, lettersReducer)
    },
    sagas: [rootSaga],
    //initialActions: [{type: 'LETTERS_MODULE_LOADED'}]
    //finalActions: [{type: 'LETTERS_MODULE_DESTROYED'}]
  }
}
